/* eslint-disable jsx-a11y/iframe-has-title */
import React, { FC } from 'react';
import {
  Iframe,
  Line,
  Text,
  TextHours,
  Title,
  Wrapper,
  WrapperContact,
} from './styles';

type Props = {};

const Footer: FC<Props> = () => (
  <Wrapper>
    <Line>
      <Iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d46305.431019755866!2d-46.68253247678766!3d-23.57309628063782!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce577a2c426f05%3A0x885563cfed6cfe1c!2sRua%20Groenl%C3%A2ndia%2C%201917%20-%20Jardim%20America%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001434-100!5e0!3m2!1spt-BR!2sbr!4v1652488810101!5m2!1spt-BR!2sbr"
        width="600"
        height="450"
        loading="lazy"
      />

      <WrapperContact>
        <Title>AGENDE SUA VISITA</Title>
        <Text>Rua Groenlandia, 1917 Jardim América - São Paulo - SP</Text>
        <br />
        <Text>agendamento@fastfitbr.com.br</Text>
        <Text>(11) 91077-5549</Text>
        <br />
        <Text>Horario de funcionamento:</Text>

        <TextHours>Segunda-feira das 8:00 as 20:00</TextHours>
        <TextHours>Terça-feira das 8:00 as 20:00</TextHours>
        <TextHours>Quarta-feira das 8:00 as 20:00</TextHours>
        <TextHours>Quinta-feira das 8:00 as 20:00</TextHours>
        <TextHours>Sexta-feira das 8:00 as 20:00</TextHours>
        <TextHours>Sábado das 10:00 ás 14:00</TextHours>
      </WrapperContact>
    </Line>
  </Wrapper>
);

export default Footer;
