import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: ${primaryMain};
  padding-bottom: 2rem;

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const WrapperText = styled.div`
  width: 85%;
  background-color: ${primaryMain};
  flex-direction: column;
  margin-left: 50px;

  @media ${inMobile} {
    width: 95%;
    margin-left: 5px;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Description = styled.h3`
  width: 100%;
  color: white;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 200;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
  }
`;
