import React, { FC } from 'react';
import { TechImg } from '~/assets/img';
import { Description, Image, Title, Wrapper, WrapperText } from './styles';

type Props = {};

const Technology: FC<Props> = () => (
  <Wrapper>
    <Image src={TechImg} />

    <WrapperText>
      <Description>
        A tecnologia do Onix Duet é tão incrível que você pode vir direto do
        trabalho ou de alguma reunião e nem precisa trocar de roupa, é deitar na
        maca,usar e esperar os resultados. <br /> <br /> E tem mais!
        <br /> <br /> Diferentemente do seu irmão, o Onix , o Ônix Duet, pode
        trabalhar 2 grupos musculares simultaneamente, gerando economia do seu
        tempo em 30 minutos. É só deitar, relaxar que o Ônix Duet fará todo
        trabalho por você! <br /> <br />
      </Description>
    </WrapperText>
  </Wrapper>
);

export default Technology;
