import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

export const Section = styled.div`
  width: 85%;
  margin: 0 auto;
  flex-direction: column;

  @media ${inMobile} {
    width: 90%;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Image = styled.img`
  width: 33%;
  object-fit: contain;
  border-radius: 20px;

  @media ${inMobile} {
    border-radius: 10px;
    margin: 0 auto;
    width: 90%;
    margin-bottom: ${pxToRem(10)};
  }
`;

export const WrapperImgs = styled.div`
  height: 25rem;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-between;

  @media ${inMobile} {
    height: auto;
    flex-direction: column;
  }
`;
