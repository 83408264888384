/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import {
  IMG1,
  IMG10,
  IMG11,
  IMG12,
  IMG13,
  IMG14,
  IMG15,
  IMG16,
  IMG17,
  IMG18,
  IMG19,
  IMG2,
  IMG20,
  IMG21,
  IMG22,
  IMG23,
  IMG3,
  IMG4,
  IMG5,
  IMG6,
  IMG7,
  IMG8,
  IMG9,
} from '~/assets/img/loja';
import {
  Description,
  Imagem,
  Section,
  Title,
  TitleSection,
  Wrapper,
} from './styles';

type Props = {};

const imgs = [
  IMG1,
  IMG10,
  IMG11,
  IMG12,
  IMG13,
  IMG14,
  IMG15,
  IMG16,
  IMG17,
  IMG18,
  IMG19,
  IMG2,
  IMG20,
  IMG21,
  IMG22,
  IMG23,
  IMG3,
  IMG4,
  IMG5,
  IMG6,
  IMG7,
  IMG8,
  IMG9,
];

const Galery: FC<Props> = () => (
  <>
    <TitleSection>Galeria</TitleSection>
    <Wrapper>
      <Section
        isRTL
        pagination={false}
        initialFirstItem={3}
        initialActiveIndex={24}
        breakPoints={[
          { width: 1, itemsToShow: 1 },
          { width: 550, itemsToShow: 2 },
          { width: 850, itemsToShow: 3 },
          { width: 1150, itemsToShow: 3 },
          { width: 1450, itemsToShow: 3 },
          { width: 1750, itemsToShow: 3 },
        ]}
      >
        {imgs.map((img, idx) => (
          <Imagem src={img} key={`1-${idx}`} />
        ))}
      </Section>
    </Wrapper>
  </>
);

export default Galery;
