import React, { FC } from 'react';
import { pushRotate } from 'react-burger-menu';
import { InstaImg, Medium, WhatsImg } from '~/assets/img';
import { Button, LogoWhiteStyled, MenuItems, Text, Wrapper } from './styles';

type Props = {};

const Header: FC<Props> = () => (
  <Wrapper>
    <LogoWhiteStyled />
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center ',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center ',
          flexDirection: 'row',
          marginRight: 10,
        }}
      >
        <a
          href="http://medium.com/f.mello_1899"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={Medium}
            alt=""
            style={{
              width: 80,
              height: 35,
              marginRight: 5,
              objectFit: 'contain',
            }}
          />
        </a>
        <a
          href="http://medium.com/f.mello_1899"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          <p> NOSSO BLOG</p>
        </a>
      </div>

      <a href="https://wa.me/+5511910775549" target="_blank" rel="noreferrer">
        <img
          src={WhatsImg}
          alt=""
          style={{
            width: 35,
            height: 35,
            marginRight: 10,
          }}
        />
      </a>

      <a
        href="https://www.instagram.com/fastfitbroficial/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={InstaImg}
          alt=""
          style={{
            width: 35,
            height: 35,
          }}
        />
      </a>
    </div>
  </Wrapper>
);

export default Header;
