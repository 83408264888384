import styled from 'styled-components';
import { getTheme } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${primaryMain};
  margin-top: 2rem;

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const WrapperContact = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: 80px;

  @media ${inMobile} {
    margin-left: 0px;
    padding: 10px;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 30px;
`;

export const Text = styled.h3`
  margin-top: -15px;
  color: #fff;
  font-weight: 500;
`;

export const TextHours = styled.h3`
  margin-top: -15px;
  color: #fff;
  font-weight: 400;
`;

export const Iframe = styled.iframe`
  width: 600px;
  height: 400px;

  @media ${inMobile} {
    width: 100%;
  }
`;
