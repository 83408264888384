import React, { FC } from 'react';
import {
  CardLeft,
  CardRight,
  Content,
  Description,
  ImageOnixRoom,
  ImageRoom,
  Line,
  Title,
  Wrapper,
} from './styles';

type Props = {};

const Locally: FC<Props> = () => (
  <Wrapper>
    <Content>
      <CardLeft>
        <ImageRoom />
      </CardLeft>

      <CardRight>
        <ImageOnixRoom />
      </CardRight>
    </Content>
  </Wrapper>
);

export default Locally;
