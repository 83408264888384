import styled from 'styled-components';
import { LogoWhite } from '~/assets/svg';
import { getTheme } from '~/utils';
import Typography from '../Typography';

// Spacing
const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const giantSpacing = getTheme('giantSpacing');

const inMobile = getTheme('inMobile');

// Colors
const primaryContrast = getTheme('primary.contrast');

export const Wrapper = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${mediumSpacing};
  height: 120px;
  position: absolute;
  top: 0;

  @media ${inMobile} {
    padding: ${mediumSpacing} ${mediumSpacing};
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${inMobile} {
    display: none;
  }
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  color: ${primaryContrast};
`;

export const Text = styled(Typography).attrs({
  variant: 'button',
})`
  color: ${primaryContrast};
  font-weight: bold;
  :hover {
    color: red;
    transition: 1s;
  }
`;

export const LogoWhiteStyled = styled(LogoWhite)`
  width: 90px;

  @media ${inMobile} {
    width: 60px;
  }
`;
