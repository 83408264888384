import styled from 'styled-components';
import { OnixRoom, Room } from '~/assets/img';
import { getTheme, pxToRem } from '~/utils';

// Colors
const primaryMain = getTheme('primary.main');
const primaryContrast = getTheme('primary.contrast');

const inMobile = getTheme('inMobile');

// Spacing
const mediumSpacing = getTheme('mediumSpacing');

export const Wrapper = styled.div`
  background-color: ${primaryMain};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${mediumSpacing} 0px;
  height: ${pxToRem(700)};

  @media ${inMobile} {
    height: ${pxToRem(800)};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;

  @media ${inMobile} {
    flex-direction: column;
    width: 90%;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const CardLeft = styled.div`
  width: 51%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 50px;

  position: absolute;
  left: 0;
  margin-top: 100px;

  border-bottom-right-radius: 50px;

  @media ${inMobile} {
    width: 100%;
    margin-top: 300px;
  }
`;

export const CardRight = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 50px;

  border-top-left-radius: 50px;

  position: absolute;
  right: 0;
  margin-top: -100px;

  @media ${inMobile} {
    width: 100%;
    margin-top: 50px;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  color: ${primaryMain};
  text-transform: uppercase;
`;

export const Description = styled.h3`
  font-size: 15px;
  color: ${primaryMain};
  text-align: center;
  width: 300px;
`;

export const ImageOnixRoom = styled.img.attrs({
  src: OnixRoom,
})`
  object-fit: cover;
  width: ${pxToRem(680)};
  height: ${pxToRem(450)};

  @media ${inMobile} {
    width: ${pxToRem(300)};
    height: ${pxToRem(300)};
  }
`;

export const ImageRoom = styled.img.attrs({
  src: Room,
})`
  object-fit: cover;
  width: ${pxToRem(680)};
  height: ${pxToRem(400)};

  @media ${inMobile} {
    width: ${pxToRem(300)};
    height: ${pxToRem(300)};
  }
`;
