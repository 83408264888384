import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 90%;
  align-self: center;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: ${primaryMain};
  padding-bottom: 2rem;
  justify-content: space-between;

  @media ${inMobile} {
    padding-bottom: 0px;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 24rem;
  height: 30rem;
  border-radius: 50px 0px 0px 50px;

  @media ${inMobile} {
    display: none;
  }
`;

export const TitleSection = styled.h3`
  margin-left: 7%;
  margin-bottom: 50px;
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const ImgFb = styled.img`
  object-fit: cover;
  width: ${pxToRem(250)};

  @media ${inMobile} {
    width: 90%;
    margin: 0 auto;
    margin-bottom: ${pxToRem(20)};
  }
`;
