import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 95%;
  align-self: center;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: ${primaryMain};
  margin-bottom: 2rem;

  @media ${inMobile} {
    padding-bottom: 0px;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 24rem;
  height: 30rem;
  border-radius: 50px 0px 0px 50px;

  @media ${inMobile} {
    display: none;
  }
`;

export const WrapperText = styled.div`
  border-radius: 10px;
  flex-direction: column;
  width: 30%;
  margin: 0px 2%;
  padding: 20px;
  background: white;

  @media ${inMobile} {
    width: 95%;
    margin-bottom: 5px;
  }
`;

export const Title = styled.h3`
  color: #222;
  font-size: 1.2rem;
  text-align: center;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Description = styled.h3`
  color: #222;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 200;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
  }
`;

export const TitleSection = styled.h3`
  margin-left: 7%;
  margin-bottom: 50px;
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Section = styled(Carousel)`
  .rec.rec-arrow {
    box-shadow: none;
    background: white;
  }
  .rec-carousel-item:focus {
    box-shadow: none;
  }
  .rec.rec-arrow:hover {
    background: white;
  }
  button {
    box-shadow: none;
    @media ${inMobile} {
      font-size: ${pxToRem(15)};
      min-width: ${pxToRem(30)};
      height: ${pxToRem(35)};
      line-height: 0;
      border: 0;
      margin: 0;
    }
  }
`;

export const Imagem = styled.img`
  width: ${pxToRem(400)};
  height: ${pxToRem(390)};
  background: #fafafa;
  object-fit: contain;
  border-radius: 10px;

  @media ${inMobile} {
    width: 100%;
    height: ${pxToRem(250)};
  }
`;
