import React, { FC } from 'react';
import { BannerIMG, OnixBanner } from '~/assets/img';
import Header from '../Header';
import {
  Description,
  HeaderFooter,
  ImageBanner,
  Img,
  RotateDiv,
  RotateDivLarge,
  TextHeader,
  Wrapper,
} from './styles';

type Props = {};

const Banner: FC<Props> = () => (
  <Wrapper>
    <Header />
  </Wrapper>
);

export default Banner;
