import React, { FC } from 'react';
import { EvaluationOne, EvaluationThree, EvaluationTwo } from '~/assets/img';
import { Image, Section, Title, WrapperImgs } from './styles';

type Props = {};

const Evaluation: FC<Props> = () => (
  <Section>
    <Title>ANTES E DEPOIS</Title>
    <WrapperImgs>
      <Image src={EvaluationOne} />
      <Image src={EvaluationTwo} />
      <Image src={EvaluationThree} />
    </WrapperImgs>
  </Section>
);

export default Evaluation;
