import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

// Colors
const primaryContrast = getTheme('primary.contrast');
const primaryMain = getTheme('primary.main');

const inMobile = getTheme('inMobile');

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${primaryMain};
  height: ${pxToRem(200)};

  @media ${inMobile} {
    height: ${pxToRem(150)};
  }
`;

export const Img = styled.img`
  width: 100vw;
  object-fit: cover;

  @media ${inMobile} {
    height: 45vh;
  }
`;

export const Description = styled.h3`
  position: absolute;
  font-size: 2.8rem;
  top: 10rem;
  left: 140px;
  font-weight: 500;
  color: ${primaryContrast};

  @media ${inMobile} {
    font-size: 1rem;
    top: 5.5rem;
    left: 20px;
  }
`;

export const HeaderFooter = styled.div`
  background: #471f4a;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextHeader = styled.h2`
  width: 50%;
  z-index: 1;
  font-weight: 500;
  color: ${primaryMain};
  font-size: 1.5rem;
  position: absolute;
  top: 320px;
  right: ${pxToRem(80)};

  @media ${inMobile} {
    display: none;
    font-size: 1.3rem;
  }
`;

export const RotateDiv = styled.div`
  width: ${pxToRem(700)};
  height: 450px;
  background-color: white;
  transform: rotate(-10deg);
  position: absolute;
  top: 200px;
  left: -100px;
`;

export const RotateDivLarge = styled.div`
  width: 150vw;
  height: ${pxToRem(480)};
  background-color: white;
  transform: rotate(-10deg);
  position: absolute;
  top: 280px;
  right: -100px;
`;

export const ImageBanner = styled.img`
  z-index: 1;
  width: 310px;
  position: absolute;
  top: 220px;
  left: ${pxToRem(120)};

  @media ${inMobile} {
    width: 290px;
    top: 235px;
    left: 50px;
  }
`;
