import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${primaryMain};
  padding-bottom: 2rem;

  @media ${inMobile} {
    flex-direction: column;
    height: ${pxToRem(500)};
  }
`;

export const Image = styled.img`
  width: 24rem;
  height: 30rem;
  border-radius: 50px 0px 0px 50px;

  @media ${inMobile} {
    display: none;
  }
`;

export const WrapperText = styled.div`
  flex-direction: column;
  width: 50%;
  margin-left: 50px;

  @media ${inMobile} {
    width: 95%;
    margin-left: 5px;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Description = styled.h3`
  color: white;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 200;
  margin-top: -10px;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
  }
`;
