import styled from 'styled-components';
import { ManImg } from '~/assets/img';
import { getTheme } from '~/utils';

// Colors
const primaryMain = getTheme('primary.main');
const primaryContrast = getTheme('primary.contrast');

const inMobile = getTheme('inMobile');

export const Wrapper = styled.div`
  background-color: ${primaryMain};
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const CardText = styled.div`
  background-color: ${primaryContrast};
  width: 500px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${inMobile} {
    width: 100%;
    height: 250px;
  }
`;

export const Text = styled.h3`
  font-size: 25px;
  color: ${primaryMain};
  text-transform: uppercase;
  text-align: center;
`;

export const Image = styled.img.attrs({
  src: ManImg,
})`
  object-fit: cover;
  width: 500px;
  height: 350px;

  @media ${inMobile} {
    width: 100%;
  }
`;
