import styled from 'styled-components';
import { LogoWhite } from '~/assets/svg';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 100%;
  height: ${pxToRem(400)};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  background-color: ${primaryMain};

  @media ${inMobile} {
    flex-direction: column;
    height: ${pxToRem(500)};
  }
`;

export const Image = styled.img`
  width: 20rem;
  height: 20rem;
  border-radius: 50px;
  object-fit: cover;

  @media ${inMobile} {
    width: 90%;
    height: 250px;
  }
`;

export const WrapperText = styled.div`
  flex-direction: column;
  width: 50%;
  @media ${inMobile} {
    margin-top: 50px;
    width: 100%;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Description = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;
  font-weight: 200;
  margin-top: -10px;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
  }
`;

export const Logo = styled(LogoWhite)`
  width: 250px;
  height: 250px;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
  }
`;
