/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { WhatsImg } from '~/assets/img';
import {
  About,
  Acting,
  Banner,
  Equipment,
  Evaluation,
  Feedback,
  Footer,
  Gallery,
  Locally,
  Promotional,
  Recommendations,
  Technology,
} from '~/components';
import { Scene, Text } from './styles';

const firebaseConfig = {
  apiKey: 'AIzaSyD7tkWnMThMAyoAdXHYSGwPQ13gKY9ZZw0',
  authDomain: 'fastfit-53780.firebaseapp.com',
  projectId: 'fastfit-53780',
  storageBucket: 'fastfit-53780.appspot.com',
  messagingSenderId: '192082116293',
  appId: '1:192082116293:web:150ae15e0253704d433aac',
  measurementId: 'G-XGNCVCMD93',
};

const Home: FC = (): JSX.Element => {
  const showSettings = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }, []);

  return (
    <Scene>
      <Banner />
      <About />
      <Locally />
      <Equipment />
      <Technology />
      <Acting />
      <Recommendations />
      <Feedback />
      <Gallery />
      <Evaluation />
      <Promotional />
      <Footer />
      <a href="https://wa.me/+5511910775549" target="_blank" rel="noreferrer">
        <img
          src={WhatsImg}
          alt="fastfit"
          style={{
            width: 50,
            height: 50,
            position: 'fixed',
            right: 20,
            bottom: 20,
            zIndex: 2,
          }}
        />
      </a>
    </Scene>
  );
};

export default Home;
