import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: ${primaryMain};

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 25rem;
  height: 27rem;
  border-radius: 0px 50px 50px 0px;
  background-color: white;
  padding: 40px;

  @media ${inMobile} {
    width: 100%;
    height: 20rem;
    object-fit: contain;
    padding: 0px;
    border-radius: 0px;
    margin-top: 2rem;
  }
`;

export const WrapperText = styled.div`
  flex-direction: column;
  margin-left: 5rem;
  width: 60%;

  @media ${inMobile} {
    width: 95%;
    margin-left: 5px;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Description = styled.h3`
  color: white;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 200;
  margin-top: 5rem;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
    margin-top: 2rem;
  }
`;
