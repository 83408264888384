import React, { FC } from 'react';
import { OnixImage } from '~/assets/img';
import { Description, Image, Title, Wrapper, WrapperText } from './styles';

type Props = {};

const Equipment: FC<Props> = () => (
  <Wrapper>
    <WrapperText>
      <Title>Nossos equipamentos</Title>

      <Description>
        O Ônix Duet, é o equipamento mais moderno quando se trata de bem estar
        corporal. <br />
        <br />
        Ele reduz gordura, enquanto fortalece grandes grupos musculares através
        da energia eletromagnética focal. Isso acontece pois o excesso de ácido
        graxos livres desencadeia a apoptose da célula de gordura, reduzindo em
        até 25% da camada subcutânea, ao mesmo tempo tonifica, define e gera
        hipertrofia dos músculos aumentando também seu volume em pelo menos 25%.
        do músculo na região trabalhada
        <br />
        <br /> O tecido muscular é estimulado a se adaptar às condições
        extremas, o que ocasiona tonificação de suas estruturas funcionais.
        <br /> <br />O Ônix Duet realiza contrações internas supra-máximas , ele
        pode chegar a marca de 36.000 contrações a cada 30 minutos! seguindo os
        protocolos e diretrizes do tratamento o resultado após 8 as sessões é
        Incrível, com certeza você sentirá uma grande mudança na gordura
        localizada e nos músculos trabalhados.
      </Description>
    </WrapperText>

    <Image src={OnixImage} />
  </Wrapper>
);

export default Equipment;
