import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { Routing } from '~/routes';
import { Storage } from '~/services';
import { RouterStore } from '~/stores';
import Home from './Home';

type Props = {
  routing: RouterStore;
};

const HomeContainer: FC<Props> = ({ routing }): JSX.Element => <Home />;
export default inject('routing')(observer(HomeContainer));
