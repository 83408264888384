import React, { FC } from 'react';
import { Description, Title, Wrapper, WrapperText } from './styles';

type Props = {};

const Recommendations: FC<Props> = () => (
  <Wrapper>
    <WrapperText>
      <Title>Recomendações</Title>
      <Description>
        Para uma melhor performance e resultado é importante realizar exercícios
        regulares paralelamente aos tratamentos oferecidos na FASTFIT. <br />
        <br />
        Assim seu corpo ficará da forma com que gostaria mais rápido e por mais
        tempo. Caso não pratique exercícios físicos, venha da mesma forma! Você
        alcançará seu objetivo, apenas poderá levar mais tempo e mais sessões
        para chegar lá!
        <br />
        <br />
        O tratamento não tem limite de idade, pode ser feito por todas as
        pessoas de todas as idades!
        <br />
        <br />
        Algumas informações para que você aproveite ao máximo a nossa
        tecnologia:
        <br /> <br />
        - É proibido usar o equipamento nos músculos peitorais e dorsais, pois
        ele trabalha com energia magnética e pode causar taquicardia e problemas
        vasculares .<br /> <br />- Também não é recomendavel para mulheres que
        possuam DIU de metal.
        <br />
        <br /> <br />
      </Description>
    </WrapperText>
  </Wrapper>
);

export default Recommendations;
