/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import {
  FbIMG1,
  FbIMG10,
  FbIMG11,
  FbIMG12,
  FbIMG2,
  FbIMG3,
  FbIMG4,
  FbIMG5,
  FbIMG6,
  FbIMG7,
  FbIMG8,
  FbIMG9,
} from '~/assets/img/feedback';
import { ImgFb, TitleSection, Wrapper } from './styles';

type Props = {};

const Feedback: FC<Props> = () => (
  <>
    <TitleSection>Depoimentos</TitleSection>
    <Wrapper>
      <ImgFb src={FbIMG1} />
      <ImgFb src={FbIMG2} />
      <ImgFb src={FbIMG3} />
      <ImgFb src={FbIMG4} />
    </Wrapper>

    <Wrapper>
      <ImgFb src={FbIMG5} />
      <ImgFb src={FbIMG6} />
      <ImgFb src={FbIMG7} />
      <ImgFb src={FbIMG8} />
    </Wrapper>

    <Wrapper>
      <ImgFb src={FbIMG9} />
      <ImgFb src={FbIMG10} />
      <ImgFb src={FbIMG11} />
      <ImgFb src={FbIMG12} />
    </Wrapper>
  </>
);

export default Feedback;
