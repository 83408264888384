import styled from 'styled-components';
import { Typography } from '~/components';
import { getTheme } from '~/utils/helpers';

// Colors
const primaryContrast = getTheme('primary.contrast');
const primaryDark = getTheme('primary.dark');

const inDesktop = getTheme('inDesktop');

export const Scene = styled.div`
  flex-direction: column;
`;

export const WrapperMenu = styled.div`
  @media ${inDesktop} {
    display: none;
  }
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
`;

export const Text = styled(Typography).attrs({
  variant: 'button',
})`
  font-weight: bold;
  color: ${primaryDark};
`;
