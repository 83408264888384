import React, { FC } from 'react';
import { PromotionalOne, PromotionalTwo } from '~/assets/img';
import {
  Cards,
  Description,
  Image,
  PromotionalDescription,
  PromotionalTitle,
  Section,
  Title,
  Wrapper,
  WrapperCards,
} from './styles';

type Props = {};

const Promotional: FC<Props> = () => (
  <Section>
    <Title>PREÇOS, PLANOS E PROMOÇÕES</Title>

    <WrapperCards>
      <Image src={PromotionalOne} />

      <Image src={PromotionalTwo} />
    </WrapperCards>

    {/* <WrapperCards>
      <Cards>
        <PromotionalTitle>PLANO AVANÇADO</PromotionalTitle>
        <PromotionalDescription>
          Terá direito a 8 sessões mensais para 2 grupos musculares pelo período
          de 12 meses.
        </PromotionalDescription>
      </Cards>
    </WrapperCards> */}

    {/* <Title>PACOTES</Title>
    <WrapperCards>
      <Cards>
        <PromotionalTitle>PACOTE ANUAL 42 SESSÕES</PromotionalTitle>
        <PromotionalDescription>
          Terá direito a 4 tratamentos de 8 sessões +10 sessões avulsas para
          utilizar no período de 1 ano.
        </PromotionalDescription>
      </Cards>

      <Cards>
        <PromotionalTitle>PACOTE SEMESTRAL 28 SESSÕES</PromotionalTitle>
        <PromotionalDescription>
          Terá direito a 3 tratamentos completos de 8 sessões + 4 sessões
          avulsas para utilizar no período de 6 meses.
        </PromotionalDescription>
      </Cards>
    </WrapperCards> */}

    {/* <WrapperCards>
      <Cards>
        <PromotionalTitle>PACOTE TRIMESTRAL 16 SESSÕES</PromotionalTitle>
        <PromotionalDescription>
          Terá direito a 2 tratamentos de 8 sessões para utilizar no período de
          3 meses.
        </PromotionalDescription>
      </Cards>

      <Cards>
        <PromotionalTitle>PACOTE BÁSICO</PromotionalTitle>
        <PromotionalDescription>
          8 Sessões para utilizar em um período de 3 meses.
        </PromotionalDescription>
      </Cards>
    </WrapperCards> */}
  </Section>
);

export default Promotional;
