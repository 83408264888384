import React, { FC } from 'react';
import { ActingImg } from '~/assets/img';
import { Description, Image, Title, Wrapper, WrapperText } from './styles';

type Props = {};

const Acting: FC<Props> = () => (
  <Wrapper>
    <WrapperText>
      <Title>Como atuamos</Title>
      <Description>
        FastFit visa popularizar tecnologias que antes eram restritas às
        clínicas de estética, com um valor muito alto e nada justo. <br />{' '}
        <br />
        Na FastFit teremos os mais modernos equipamentos e SEMPRE o menor preço
        do mercado.
      </Description>
    </WrapperText>
    <Image src={ActingImg} />
  </Wrapper>
);

export default Acting;
