import React, { FC } from 'react';
import { Woman1Img } from '~/assets/img';
import {
  Description,
  Image,
  Logo,
  Title,
  Wrapper,
  WrapperText,
} from './styles';

type Props = {};

const Results: FC<Props> = () => (
  <Wrapper>
    <Logo />

    <WrapperText>
      <Title>Tecnologia a serviço do corpo</Title>

      <Description>
        A FastFit é uma empresa que tem como objetivo apresentar a mais alta
        tecnologia de vanguarda mundial em tratamentos corporais,
        fisioterapêuticos e estéticos. Buscamos sempre os melhores e mais
        modernos equipamentos para que você alcance o seu bem estar corporal e
        mental!
      </Description>
    </WrapperText>
  </Wrapper>
);

export default Results;
