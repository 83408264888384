import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

export const Section = styled.div`
  width: 85%;
  margin: 0 auto;
  flex-direction: column;

  @media ${inMobile} {
    width: 90%;
  }
`;

export const Wrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${primaryMain};
  padding-bottom: 2rem;

  @media ${inMobile} {
    width: 100%;
    flex-direction: column;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 1.5rem;
  text-align: left;

  @media ${inMobile} {
    width: auto;
    font-size: 1rem;
  }
`;

export const Description = styled.p`
  width: 100%;
  color: white;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 200;

  @media ${inMobile} {
    width: 100%;
    font-size: 1rem;
  }
`;

export const WrapperCards = styled.div`
  flex-direction: row;
  width: 85%;
  margin: 0 auto;
  justify-content: space-between;

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const Cards = styled.div`
  width: 49%;
  flex-direction: column;
  border-radius: 10px;
  min-height: 200px;
  background: #fafafa;
  margin: 5px;
  padding: 10px;

  @media ${inMobile} {
    margin: 5px 0px;
    width: 100%;
  }
`;

export const PromotionalTitle = styled.h3``;

export const PromotionalDescription = styled.h3`
  font-weight: 400;
`;

export const Image = styled.img`
  width: ${pxToRem(550)};
  height: ${pxToRem(550)};

  border-radius: ${pxToRem(30)};
  object-fit: contain;

  @media ${inMobile} {
    width: 100%;
    margin-top: ${pxToRem(50)};
  }
`;
